<template>
  <div>
    车辆查询--NEW
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>